import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useJewelerApi } from "../utility/hooks/useApi";

export const getAccounts = createAsyncThunk("getAccounts", async () => {
  const response = await useJewelerApi().post("admin/getAccount", {
    userId: JSON.parse(localStorage.getItem("userData"))._id,
  });
  return response.data;
});
export const getTransactionType = createAsyncThunk(
  "getTransactionType",
  async () => {
    const response = await useJewelerApi().post("admin/getTransactionType", {
      userId: JSON.parse(localStorage.getItem("userData"))._id,
    });
    return response.data;
  }
);

export const getExchange = createAsyncThunk("getExchange", async () => {
  const response = await useJewelerApi().post("admin/haremAltinApi", {
    userId: JSON.parse(localStorage.getItem("userData"))._id,
  });
  return response.data;
});

const initialState = {
  accounts: [],
  transactionTypes: [],
  wallet: [],
  salerFlow: [],
  exchange: {},
};
export const currentSlice = createSlice({
  name: "current",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload.data;
    });
    builder.addCase(getTransactionType.fulfilled, (state, action) => {
      state.transactionTypes = action.payload.data;
    });
    builder.addCase(getExchange.fulfilled, (state, action) => {
      state.exchange = action.payload.data;
    });
  },
});

export default currentSlice.reducer;
