import { createSlice } from "@reduxjs/toolkit";

const initialProcessesState = {
  processes: JSON.parse(localStorage.getItem("processes")) || [],
};
const initialSalesState = {
  sales: JSON.parse(localStorage.getItem("salesCariData")) || [],
};

const processesSlice = createSlice({
  name: "processes",
  initialState: initialProcessesState,
  reducers: {
    addProcess: (state, action) => {
      state.processes.push(action.payload);
      localStorage.setItem("processes", JSON.stringify(state.processes));
    },
    deleteProcess: (state, action) => {
      state.processes.splice(action.payload, 1);
      localStorage.setItem("processes", JSON.stringify(state.processes));
    },
    resetProcess: (state) => {
      state.processes = [];
      localStorage.removeItem("processes");
    },
  },
});
const salesSlice = createSlice({
  name: "sales",
  initialState: initialSalesState,
  reducers: {
    addSales: (state, action) => {
      state.sales.push(action.payload);
      localStorage.setItem("salesCariData", JSON.stringify(state.sales));
    },
    deleteSales: (state, action) => {
      state.sales.splice(action.payload, 1);
      localStorage.setItem("salesCariData", JSON.stringify(state.sales));
      
      },
      resetSales: (state) => {
          state.sales = [];
          localStorage.removeItem("salesCariData");
          localStorage.removeItem("LocalList");
    },
  },
});

export const { addProcess, deleteProcess, resetProcess } = processesSlice.actions;
export const { addSales, deleteSales, resetSales } = salesSlice.actions;
export const processesReducer = processesSlice.reducer;
export const salesReducer = salesSlice.reducer;
