// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import current from "./current"
import user from "./user"
import {processesReducer, salesReducer} from "./combineProcessCurrent"


const rootReducer = { navbar, layout,current,user, processesReducer,salesReducer }

export default rootReducer
