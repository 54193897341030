// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { jwtDecode } from "jwt-decode";


export const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {},
    token:localStorage.getItem("auth_token") ? localStorage.getItem("auth_token") : ""
  },
  reducers: {
    handleLogin: (state, action) => {
      state.token = action.payload
      state.userData = jwtDecode(action.payload)
      localStorage.setItem("userData",JSON.stringify(jwtDecode(action.payload)))
      localStorage.setItem("auth_token",action.payload)
    },
    handleLogout: (state) => {
      state.token = ""
      state.userData = {}
      localStorage.removeItem("userData")
      localStorage.removeItem("auth_token")
      window.location.href = "/login"
    }
  },
})

export const {handleLogin, handleLogout} = userSlice.actions
export default userSlice.reducer
