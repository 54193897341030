import React, { createContext, useState, useContext } from "react";
import { Spinner } from "reactstrap";

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoader = () => {
    setLoading(true);
  };
  const hideLoader = () => {
    setLoading(false);
  };

  const shouldShowLoader = loading;

  const contextValue = {
    loading,
    showLoader,
    hideLoader,
    shouldShowLoader,
  };

  return (
    <LoaderContext.Provider value={contextValue}>
      {shouldShowLoader && (
        <div className="loader">
          <Spinner
            color="primary"
            style={{
              height: "5rem",
              width: "5rem",
            }}
          >
            Loading...
          </Spinner>
        </div>
      )}
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoader must be used within a LoaderProvider");
  }
  return context;
};
